@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i');

$base-font-family      		: 'Lato', sans-serif;
$title-font-family    		: 'Lato', sans-serif;
$subtitle-font-family 	 	: 'Lato', sans-serif;

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: 400 0.889rem/1 $base-font-family;

/* FORMS */
$button-font 							: 400 0.889rem/1 $title-font-family;