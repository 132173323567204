.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}

.chosen-filter .chosen-container-multi .chosen-choices,
select {
	background: $arrow-select no-repeat 100% -2px / 36px auto white;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	padding-right: 46px;
}

.chosen-filter {
	.chosen-container,
	.chosen-container-multi {
		margin-bottom: $vert-space/2;
    width: 100% !important;
		.chosen-choices {
			margin-bottom: 0;
			li.search-field {
				width: 100%;
				input[type="text"] {
					padding: 0;
			    height: auto;
			    min-height: auto;
			    margin: 0;
				}			
			}
			li.search-choice {				
				font-size: 0.889rem;
				display: inline-block;
				border: 1px solid $glitter;
				padding: 3px 18px 5px 5px;
				margin: 0 3px 5px 0;
				font-weight: 600;
				border-radius: 0;
				background: white;
				box-shadow: none;
			}
		}
	}
}