// /* MAIN MENU */ //

$nav-base-font        	 	: 1.22rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: transparent;
$nav-base-bg-color        		: white; 
$nav-base-text-color     		 	: $primary;
$nav-base-padding        			: 0.889rem 1.111rem;
$nav-base-hover-padding   		: 0.889rem 1.111rem;
$nav-sub-base-padding					: 0.889rem 1.111rem;
$nav-sub-base-hover-padding		: 0.889rem 1.111rem;

$nav-base-padding-smaller 				: 0.889rem 0.889rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.889rem 0.889rem;

$nav-active-bg-color      		: white;
$nav-active-text-color    		: $ube;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: $caribbean_green;
$nav-hover-text-color     		: white;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: $white_smoke;
$nav-highlight-text-color 		: $caribbean_green; 
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: $white_smoke;
$nav-sub-text-color       		: $secondary;
$nav-sub-hover-bg-color   		: $caribbean_green;
$nav-sub-hover-text-color   	: white;
$nav-sub-active-bg-color   		: transparent; //$pastel_gray;
$nav-sub-active-text-color   	: $ube;
$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;
$nav-sub-top-border						: none; //3px solid $primary;

$nav-mob-text-color      						: $secondary;
$nav-mob-active-text-color      		: $ube;
$nav-mob-active-bg-color      			: inherit;

$nav-mob-1st-bg-color     					: $glitter;
$nav-mob-1st-highlight-bg-color     : $caribbean_green;
$nav-mob-1st-highlight-text-color   : white;
$nav-mob-1st-line-color     				: $maximum_blue_purple;
$nav-mob-1st-border-color     			: $maximum_blue_purple;

$nav-mob-2nd-bg-color     					: $white_smoke;
$nav-mob-2nd-highlight-bg-color     : $caribbean_green;
$nav-mob-2nd-highlight-text-color   : white;
$nav-mob-2nd-line-color     				: $maximum_blue_purple;
$nav-mob-2nd-border-color     			: $maximum_blue_purple;

$nav-mob-3rd-bg-color     					: white;
$nav-mob-3rd-highlight-bg-color     : $caribbean_green;
$nav-mob-3rd-highlight-text-color   : white;
$nav-mob-3rd-line-color     				: $maximum_blue_purple;
$nav-mob-3rd-border-color     			: $maximum_blue_purple;

//$nav-mob-4th-bg-color     					: $azureish_white;
//$nav-mob-4th-highlight-bg-color     : $blue_bell;
//$nav-mob-4th-highlight-text-color   : $primary;
//$nav-mob-4th-line-color     				: purple;
//$nav-mob-4th-border-color     			: $dark_lavender;

$nav-mob-top-border									: none; //3px linear-gradient(90deg, #30B88D 0%, #167C98 50%, #7B7DBC 100%);
$nav-mob-border											: 1px solid $maximum_blue_purple;
$nav-mob-expanded-border						: 1px solid $maximum_blue_purple;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: white;
$nav-button-hover-bg-color			: $white_smoke;
$nav-button-height      				: 78px;
$nav-button-width      					: 86px;
$nav-button-bar-bg-color      	: $dark_grey;
$nav-button-bar-hover-bg-color  : $caribbean_green;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 	: 8px;
