.credit,
.vid-description {
	background: $slide-bg-color none repeat scroll 0 0;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: right;
	color: white;
	font-size: 0.75em;
	//margin-bottom: -27px;
	margin: 20px 0 0 0;
	padding: 1px 22px 1px 10px;
	position: absolute;
	right: -10px;
	text-align: right;
	top: 0;
	
	&:empty {
		display: none;
	}

	&.collapsed {
		background: $slide-bg-color $ico-info no-repeat 50% 50%;
		height: 42px;
		margin-right: 0;
		text-align: inherit;
		text-indent: -9999px;
		transition: background-color 0.25s linear;
		width: 42px;
		border-radius: 42px;
		margin: 10px 10px 0 0;

		&:hover {
			background-color: white;
		}
	}
} 