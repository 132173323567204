.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			float:left;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			
			.file-extension {
				padding:$vert-space*0.3 $horz-space/4;
				text-transform:uppercase;
				font-size:0.778rem;
        line-height:1;
				text-align:center;
				font-weight:bold;
			}
			.file-size {
        padding: 3px 5px;
				font-size:0.778rem;			
				text-align:center;
				color:$dim_gray;
        //border:$secondary-border;
        border-top:none;
			}
		}
		
		.file-name {
			overflow:hidden;
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
      color:white;
    }
  }
	
//// /* FILE TYPE COLOURS */
//  
//  .file-type-docx, .file-type-doc {
//		&.file-icon {
//			border:$secondary-border;
//			border-color: $docx_color;
//    	.file-extension {
//      	color:$docx_color;
//			}
//    }       
//  }
//  .file-type-xlsx, .file-type-xls {
//		&.file-icon {
//			border:$secondary-border;
//			border-color: $xlsx_color;
//    	.file-extension {
//      	color:$xlsx_color;
//			}
//    }       
//  }
//  .file-type-pptx, .file-type-ppt {
//		&.file-icon {
//			border:$secondary-border;
//			border-color: $pptx_color;
//    	.file-extension {
//      	color:$pptx_color;
//			}
//    }       
//  }
//  .file-type-pdf {
//		&.file-icon {
//			border:$secondary-border;
//			border-color: $pdf_color;
//    	.file-extension {
//      	color:$pdf_color;
//			}
//    }       
//  }
//  .file-type-rtf, .file-type-txt {
//		&.file-icon {
//			border:$secondary-border;
//			border-color: $txt_color;
//    	.file-extension {
//      	color:$txt_color;
//			}
//    }       
//  }
//  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
//		&.file-icon {
//			border:$secondary-border;
//			border-color: $jpg_color;
//    	.file-extension {
//      	color:$jpg_color;
//			}
//    }       
//  }
//  .file-type-zip, .file-type-rar, .file-type-7z {
//		&.file-icon {
//			border:$secondary-border;
//			border-color: $zip_color;
//    	.file-extension {
//      	color:$zip_color;
//			}
//    }       
//  }
} 

// PALETTE HIGHLIGHT //
.palette-highlight {
	.view-attachments {

		.attachment-link {
			.file-icon {
				.file-size {
					color: white;
				}
			}
		}
	}
}


