// /* HEADER */ //

$header-menu-font 				: /*0.875rem*/ 0.889rem/1 $base-font-family;
$header-search-field-font : 0.889rem/1.286 $base-font-family;

$header-bg-color                   : none;

$header-menu-link-color            : $dim_gray;
$header-menu-link-hover            : $action-hover;

$header-search-field-bg-color      : $mint_cream;
$header-search-field-text-color    : $dim_gray;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;

  