// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #231F20; /* black */ 
$secondary         : #2C3F4D; /* grey */ 



// /* MAIN PALETTE */ //
$dogwood_rose      : #D81E5B; /* dogwood_rose */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$light_blue        : #84D0F5; /* lightblue */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$faded_blue        : #5D86C5; /* faded_blue */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$middle_red_purple : #38023B; /* middle_red_purple */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE

$raisin_black					: #231F20;
$japanese_indigo			: #2C3F4D;
$dim_gray							: #6C6B70;

 

// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa; /* pale_grey */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$pale_grey_two     : #e4e8eb; /* pale_grey_two */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$battleship_grey   : #6b7280; /* battleship_grey */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$platinum          : #e0e0e2; /* platinum */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$pearl_aqua        : #81D2C7; /* pearl_aqua */  // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$dark_lavender     : #6250A1; /*  dark_lavender  */ // >>>>>>>>>>>>>>>>>>>>>>  DELETE


$dark_gunmental				: #262529;

$warm_indigo					: #0C3646;
$dark_imperial_blue		: #015166;
$cg_blue							: #017999;

$generic_viridian			: #018A63;
$caribbean_green			: #02BC87;

$slate_blue						: #6159CC;
$ube									: #8680D8;
$maximum_blue_purple	: #B8B4E8;

$glitter							: #E9E8F8;
$white_smoke					: #F5F5FC;
$mint_cream						: #F5F8FA;
$alice_blue						: #F0FBFF;


$black             : #000;
$grey              : #666;
$dark_grey				 : #999;
$white             : #FFF;



// MENU
$jelly_bean					: #E46747; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$middle_red					: #E28871; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$vivid_tanherine		: #EFA491; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$champagne_pink			: #F8D9D1; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$thulian_pink				: #E8789D; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$queen_pink					: #F5C7D6; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$pale_cerulean			: #9EB6DC; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$azureish_white			: #D6E1F0; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$blue_bell					: #A196C7; // >>>>>>>>>>>>>>>>>>>>>>  DELETE
$languid_lavander		: #D8D3E7; // >>>>>>>>>>>>>>>>>>>>>>  DELETE


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue_dark 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue_dark; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $generic_viridian;
$action-hover        : $ube;
$action-active       : $ube; 
$action-active-hover : $ube; 

$action-alt      					: $caribbean_green;
$action-alt-hover        	: $ube;
$action-alt-active       	: $ube; 
$action-alt-active-hover 	: $ube; 


// /* SHADOWS */ //
$box-shadow	: 10px 10px 30px 0 rgba(0,0,0,0.1);


// /* BORDERS */ //
$primary-border-color          : $ube; //rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : $maximum_blue_purple; //rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

//$tertiary-border-color         : $ube;
//$tertiary-border-radius        : 0;
//$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : $primary-border-color;
$table-cell-border        : 1px solid $secondary-border-color;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $white_smoke;
$table-header-color    		: $primary;
$table-header-border      : 1px solid $primary-border-color;
$table-header-cell-border : 1px solid $primary-border-color;
$table-hover-bg-color     : lighten($platinum, 10%);
$table-cell-color    			: $secondary;
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $cg_blue;
$form-box-shadow       : none; //$box-shadow;
$form-box-shadow-focus : none; //$box-shadow; 
$form-border-color     : $cg_blue;
$form-border           : 1px solid $form-border-color;
$form-border-radius    : 0;

$button-bg             				: $caribbean_green; 
$button-text           				: white;
$button-border-color	 				: $caribbean_green;
$button-border         				: 1px solid $button-border-color;
$button-border-radius  				: 30px;
$button-hover-bg       				: $ube;
$button-hover-text     				: white;
$button-hover-border-color	  : $ube;
$button-hover-border   				: 1px solid $button-hover-border-color;

$button-alt-1-bg             				: white;
$button-alt-1-text           				: $caribbean_green;
$button-alt-1-border-color	 				: $caribbean_green;
$button-alt-1-border         				: 1px solid $button-alt-1-border-color;
$button-alt-1-border-radius  				: 0;
$button-alt-1-hover-bg       				: white;
$button-alt-1-hover-text     				: $ube;
$button-alt-1-hover-border-color	 	: $ube;
$button-alt-1-border         				: 1px solid $button-alt-1-hover-border-color;
 


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;