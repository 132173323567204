// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:25px;margin-bottom: 10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:10;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:1.25rem;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:1.25rem;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 70px;
		li {
//			@include media($narrow) {
//				background-color: $slide-bg-color;
//			}
			margin: 0;
			padding: 0;
			position: relative;
			width: 28px;
			height: 28px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
} 

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 20px;
		
		@include media($narrow) {
			padding: 25px;
		}
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		max-width: 600px;
		@include media($narrow) {
			width: 50%;
		}
		@include media($normal) {
			width: 40%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				max-width: none;
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}


// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 20px;
		
		@include media($narrow) {
			padding: 25px;
		}
	}
	.slick-dots {
		bottom:10px;
	}	
}






// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				//background-color:$slide-text-bg-color;
				background-color:$primary;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					//.card-slide-description
					.card-description-inner {
						bottom:0;
						position:relative;
						//background-color:$primary;						
						font:$slide-text-font;
						padding:17px 20px 20px 20px;
						margin:0;
						//color:$slide-text-color;
						min-height:66px;	
					
						p {
							font:$slide-text-font;
							margin: 0;
						}
						
						a.slide-link-button {
							border-radius: 40px;
							background-color: $action-alt;
							display: inline-block;
							padding: 10px 20px;
							font-size: 0.889rem;
							text-align: center;
							color: white;
							text-transform: uppercase;
    					margin-top: 10px;	

							&:hover {
								background-color:$action-alt-hover;
							}
						}
					}
				}
			} 
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:10px;
		width:100%;
		left:0;
		@include media($narrow) {
			width: calc(100% - 40px);
    	left: 20px;
			bottom: 20px;
		}
	}
}

.section-with-gallery {
	.view-footer {
		margin-top: $vert-space;
	}
}

.section-with-slider:not(.side-row) .view-slider {
	.slick-nav {
		button.slick-arrow {
			padding: 20px;
			transition: background-color 300ms linear 0s;

			@include media($narrow) {
				padding: 30px;
			}
		}
		
		.slick-dots {
			li {
				@include media($narrow) {
					background-color: $slide-bg-color;
				}
			}
		}
	}

		
	.view-content {
		.slick-list {
			.slick-track {				
				.views-row {
					.card-description-inner {
						bottom:0;
						position:relative;
						background-color:$primary;						
						font:$slide-text-font;
						padding:17px 20px 85px 20px;
						margin:0;
						color:$slide-text-color;
						min-height:66px;
						
						a.slide-link-button {
							border-radius: 40px;
							background-color: $action-alt;
							display: inline-block;
							padding: 10px 20px;
							font-size: 0.889rem;
							text-align: center;
							color: white;
							text-transform: uppercase;
    					margin-top: 10px;
							transition: background-color 300ms linear 0s;

							&:hover {
								background-color:$action-alt-hover;
							}
						}

						@include media($narrow) {
							background-color:$slide-text-bg-color;
							width:75%;
							min-height: 80px;
							background-color:$slide-text-bg-color;
							position:absolute;
							padding:10px 20px;
							display: flex;
							align-items: center;
							left: -10px;
							bottom: 80px;
							max-width: 700px;
							
							.card-slide-description {
								width: 100%;
							}
							
							.card-slide-link-button {
								display: inline-block;
								height: 80px;
								width: 80px;
								margin-left: 40px;
								
								a.slide-link-button {
									max-width: 80px;
									max-height: 80px;
									display: block;
									padding: 27px 0;
									text-align: center;
									width: 80px;
									height: 80px;
									margin: 0;
								}
							}
						}
						
						@include media($normal) {
							width:45%;
							bottom: 90px;
						}
						
						@include media($wide) {
							bottom: 120px;
						}
					}
				}
			}
		}
	}
}

.section-row .view-slider {
	overflow: hidden;
	
	.view-content .slick-list .slick-track .views-row {
	
		@include media($narrow) {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front .content-hero {
	.section-row.section-with-slider {
		background: white !important;
		.section-inner {
			margin: 0 auto;
			max-width:100%; //$site-max-width;
			padding:0;
			position: relative;

			@media (min-width:1600px) {
				max-height: 450px;
				overflow: hidden;

				.view-slider .view-content .slick-list .slick-track .views-row {
					margin: 0;

					.card-image {
						.card-slide-image {
							position:relative;
							img {
								max-width:none;
								position:relative;
								width: 100%;

								@include media($narrow) {
									left: -10px;
									width: calc(100% + 20px);
								}

								@include media($wide) {
									left:calc(50% - 800px);
									width: auto;
								}							

								@media (min-width:1600px) {
									left:0;
									width: 100%;
								}
							}
						}
					}

					.card-description-inner {
						bottom: auto;
						top: 230px;
						left: calc(50% - 800px);
						

						.card-slide-description {
						}
					}
				}
			}

			.view-slider .slick-slider:not(.slick-dotted) {
				.card-description-inner.card-description-empty {
					display: none;
				}
			}

			.view-slider .view-footer {
				@include media(1600px) {
					top: calc(450px - 70px);
					bottom:auto;
					left: calc(50% - 800px + 20px);
					max-width: calc(1600px - 40px);
				}
			}
		}
	}
}
 

// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;
				
				width: calc(100% - 40px);
				margin-bottom: 20px;
				margin-left: 20px;				
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}



