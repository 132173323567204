// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	transition: $card-transition;
	
	&.card-with-image {
		box-shadow: none;
		overflow: visible;
		transition: none;
		display: flex;
		flex-direction: column;
		
		& >  div {
			box-shadow:$card-shadow;
			display: block;
			
			&.card-text {
				transition: $card-transition;
				margin-right: 20px;
				margin-top: -20px;
				position: relative;
				height: 100%;
			}
		}
	}

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font; 
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
} 


@media (min-width: 540px) {	
	.row-max-1 article:not(.node-teaser-image) {
		.card.card-with-image {
			overflow: visible;
    	display: flex;
			flex-direction: row;
			
			.card-image {
				float: none;
				align-self: flex-start;
			}
			
			.card-text {
				margin-top: 20px;
				margin-right: 0;
				margin-left: -20px;
				height: 100%;
				align-self: flex-start;
				
				width: calc(60% + 20px);
			}
		}
	}
	
	.page-node:not(.sidebar-second) main .section-row:not(.view-type-slider).row-max-2 .views-row .card-with-image {
    overflow: visible;
	}
}

@media (min-width: 60em) {
	.row-max-2 article:not(.node-teaser-image) {
		.card.card-with-image {
			overflow: visible;
    	display: flex;
			flex-direction: row;
			
			.card-image {
				float: none;
				align-self: flex-start;
			}
			
			.card-text {
				margin-top: 20px;
				margin-right: 0;
				margin-left: -20px;
				height: auto;
				align-self: flex-start;
				
				width: calc(52% + 20px);
			}
		}
	}

	.postscript-first .section-row:not(.view-type-slider).row-max-2 .views-row .card-with-image {
    overflow: visible;
	}
}

.card-display-date {
	//background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	//color:$card-date-text-color;
	font:$card-date-font;
	padding:0 0 12px; //12px 20px 10px;
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

//			@media (min-width:0) and (max-width:719px) {
//				display: block;
//			}

			&:after {
				content:" ";
				display:inline-block;
				height:20px;
				margin:0 0 -2px 10px;
				width:20px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	text-transform: uppercase;
	a { 
		display: flex;
		padding-top: 4px;
		
		&:after {
			content: "";
			display: inline-block;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-left: 10px;
			vertical-align: sub;
			margin-top: -4px;
		}
	}
}
 


// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location {
	font-size:0.875em;
	line-height:1.57142857142857;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-date-display-override {
	font-size: 0.875em;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875rem;
}
.card-email {
	font-weight: 600;
	font-size:0.889rem;
}

.card-job-title,
.card-institution,
.card-mail{
	font-size:1rem;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;