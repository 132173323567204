fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

.chosen-container-multi .chosen-choices,
#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;
	min-height: 42px;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

.chosen-filter .chosen-container-multi .chosen-choices,
.chosen-filter .chosen-container-multi .chosen-choices li.search-field input[type="text"],
select {
	background-color: white;
	width: auto;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: 0; //$vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
}


.form-checkboxes,
.form-type-checkbox {
	input[type=checkbox] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	input[type=checkbox] + label.option {
		//color: $primary;
		display:inline-block;
		line-height:0.9rem;
		background-repeat:no-repeat;
		background-position: 0 1px;
		// font-size:1.5rem;
		font-weight: 300;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		&:hover {
			//color: $primary;
		}
	}
	input[type=checkbox]:checked + label.option {
		background: $checkbox-active no-repeat scroll 0 1px / 13px 13px transparent;
		//color: $primary;
	}
	label.option {
		background: $checkbox-default no-repeat scroll 0 0 / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; 

		&:hover {
			background: $checkbox-hover no-repeat scroll 0 0 / 13px 13px transparent;
			//color:$primary;
		}
	}
	 
	.description {
		margin-left: 1.75em;
	}
}

 
.form-radio,
.form-type-radio { 
	input[type=radio] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	input[type=radio] + label.option {
		//color: $primary;
		display:inline-block;
		line-height:0.9rem;
		background-repeat:no-repeat;
		background-position: 0 1px;
		// font-size:1.5rem;
		font-weight: 300;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		&:hover {
			//color: $primary;
		}
	}
	input[type=radio]:checked + label.option {
		background: $radio-active no-repeat scroll 0 1px / 13px 13px transparent;
		//color: $primary;
	}
	label.option {
		background: $radio-default no-repeat scroll 0 0 / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			background: $radio-hover no-repeat scroll 0 0 / 13px 13px transparent;
			//color:$primary;
		}
	}
	
	.description {
		margin-left: 1.75em;
	}
}
 
 
.views-exposed-form {
	background-color: $white_smoke;
	border: none;
	padding: $vert-space $horz-space;
	margin-bottom: $vert-space;
	color: $primary;

	.views-exposed-widget-submit {
		float: right;
		display: inline-block;
		clear: left;
		
		.views-exposed-widget {
			margin-top: 0.5rem;
		
			.form-submit {
				margin: 0;
				text-transform: uppercase;
				
				&#edit-reset {
					background:$button-link-alt-bg;
					border-color: $button-link-alt-border-color;
					color: $button-link-alt-color;
					
					&:hover {
						background:$button-link-alt-hover-bg;
						border-color: $button-link-alt-hover-border-color;
						color: $button-link-alt-hover-color;
					}
				}
			}
		}
	}
	
	label {
    font-weight: normal; 
	}
}


aside .views-exposed-form {
	background-color: transparent;
	border: none;
	padding: 0;
}

// THE HUB 

fieldset#vetvaxhub {
    display: none;
}
