// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $base-font-family;
$card-title-font        	: normal 1.111rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.111rem/1 $base-font-family;
$card-feed-link-font			: normal 0.889rem/1.46153846153846 $base-font-family;


$card-shadow                   : $box-shadow;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space*1.25 !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : none; //lighten($platinum,10%);
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $dim_gray;
$card-date-font                : 0.8125em/1 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : white;
$card-hover-border             : none;
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $action-hover; //colour of card title text when you hover over it
$card-text-hover-color         : $dim_gray; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $dim_gray; 
$card-date-hover-bg-color      : transparent; // allows display dates (e.g. events) to have different hover background from the rest of the card

$card-alt-hover-bg-color           : $ube;
$card-alt-hover-border             : none;
$card-alt-hover-shadow             : none; //$box-shadow;
$card-alt-action-hover             : white; //colour of card title text when you hover over it
$card-alt-text-hover-color         : white; //colour of regular card text when you hover over it
$card-alt-date-hover-text-color    : white; 
$card-alt-date-hover-bg-color      : transparent; // allows display dates (e.g. events) to have different hover background from the rest of the card

$card-alt-2-hover-bg-color           : $cg_blue;
$card-alt-2-hover-border             : none;
$card-alt-2-hover-shadow             : none; //$box-shadow;
$card-alt-2-action-hover             : white; //colour of card title text when you hover over it
$card-alt-2-text-hover-color         : white; //colour of regular card text when you hover over it
$card-alt-2-date-hover-text-color    : white;
$card-alt-2-date-hover-bg-color      : transparent; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $cg_blue;
$card-light-action-default     : $cg_blue;
$card-light-action-hover       : $ube; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $dim_gray;
$card-light-subtitle-color     : $dim_gray;

//// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : red;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $black;
$card-medium-action-default    : $black;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $slate_blue;
$card-dark-border-color        : transparent;
$card-dark-title-color         : white;
$card-dark-action-default      : white;
$card-dark-action-hover        : white; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : white;
$card-dark-subtitle-color      : white;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $dark_imperial_blue;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : white;
$card-highlight-action-default : white;
$card-highlight-action-hover   : white; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : white;
$card-highlight-subtitle-color : white;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          		: white;
$card-more-link-default-color       		: $cg_blue;
$card-more-link-default-icon        		: $arrow-grey-right no-repeat 100% 100% / 20px auto;
$card-more-link-default-hover-bg    		: white;
$card-more-link-default-hover-color 		: $action-hover;
$card-more-link-default-hover-icon  		: $arrow-secondary-right no-repeat 100% 100% / 20px auto;

$card-more-link-alt-bg              : $slate_blue;
$card-more-link-alt-color           : white;
$card-more-link-alt-icon            : $arrow-white-right no-repeat 100% 100% / 20px auto;
$card-more-link-alt-hover-bg        : $action-hover;
$card-more-link-alt-hover-color     : white;
$card-more-link-alt-hover-icon      : $arrow-white-right no-repeat 100% 100% / 20px auto;

$card-more-link-alt-2-bg            : $dark_imperial_blue;
$card-more-link-alt-2-color         : white;
$card-more-link-alt-2-icon          : $arrow-white-right no-repeat 100% 100% / 20px auto;
$card-more-link-alt-2-hover-bg      : $cg_blue;
$card-more-link-alt-2-hover-color   : white;
$card-more-link-alt-2-hover-icon    : $arrow-white-right no-repeat 100% 100% / 20px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : none; // background for the entire link
$card-feed-link-default-color       : $dim_gray;
$card-feed-link-default-icon        : $action-alt $arrow-white-right no-repeat scroll 50% 50% / 20px 15px; //background for the default :after component
$card-feed-link-default-hover-bg    : none;
$card-feed-link-default-hover-color : $action-hover;
$card-feed-link-default-hover-icon  : $action-hover $arrow-white-right no-repeat scroll 50% 50% / 20px 15px;
 
$card-feed-link-alt-bg              : none; // background for the entire link
$card-feed-link-alt-color           : $action-alt;
$card-feed-link-alt-icon            : $action-alt $arrow-white-right no-repeat scroll 50% 50% / 20px 15px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $action-hover;
$card-feed-link-alt-hover-icon      : $action-hover $arrow-white-right no-repeat scroll 50% 50% / 20px 15px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $button-bg;
$button-link-default-border-color       : $button-border-color;
$button-link-default-color              : $button-text;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $button-hover-bg;
$button-link-default-hover-border-color : $button-hover-border-color;
$button-link-default-hover-color        : $button-hover-text;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $button-alt-1-bg;
$button-link-alt-border-color           : $button-alt-1-border-color;
$button-link-alt-color                  : $button-alt-1-text;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $button-alt-1-hover-bg;
$button-link-alt-hover-border-color     : $button-hover-border-color;
$button-link-alt-hover-color            : $button-alt-1-hover-text;
$button-link-alt-hover-icon             : none;