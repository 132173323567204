$slide-bg-color       : rgba(0, 0, 0, 0.7);

// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: $slide-bg-color $arrow-green-left no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg      				: white $arrow-green-left no-repeat scroll 50% 50%;
$slick-default-next-bg            				: $slide-bg-color $arrow-green-right no-repeat scroll 50% 50%;
$slick-default-next-hover-bg      				: white $arrow-green-right no-repeat scroll 50% 50%;
$slick-default-mob-prev-bg             				: transparent $arrow-green-left no-repeat scroll 50% 50%;
$slick-default-mob-prev-hover-bg      				: white $arrow-green-left no-repeat scroll 50% 50%;
$slick-default-mob-next-bg            				: transparent $arrow-green-right no-repeat scroll 50% 50%;
$slick-default-mob-next-hover-bg      				: white $arrow-green-right no-repeat scroll 50% 50%;
$slick-default-dot-border         						: 2px solid white;
$slick-default-dot-hover-border    						: 2px solid $caribbean_green;
$slick-default-dot-active-bg-color 						: $caribbean_green;
$slick-default-dot-active-border-color 				: 2px solid $caribbean_green;
$slick-default-dot-active-hover-bg-color 			: $action-hover; 
$slick-default-dot-active-hover-border-color 	: 2px solid $action-hover;


$slick-alt-1-nav-bg                				: transparent; //rgba(255,255,255,0.25); // used on slide and galler views
$slick-alt-1-controls-bg           				: transparent; // used on twitter views etc
$slick-alt-1-prev-bg               				: $action-alt $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         				: $action-alt-hover $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               				: $action-alt $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         				: $action-alt-hover $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-1-dot-default-border    				: 2px solid $dark_gunmental;
$slick-alt-1-dot-hover-border      				: 2px solid $action-alt-hover;
$slick-alt-1-dot-active-bg-color   				: $action-alt-hover;
$slick-alt-1-dot-active-border-color 			: 2px solid $action-alt-hover;
$slick-alt-1-dot-active-hover-bg-color 		: $action-alt;
$slick-alt-1-dot-active-hover-border-color 		: 2px solid $action-alt;


$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $action-alt $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: $action-alt-hover $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: $action-alt $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: $action-alt-hover $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: 2px solid white;
$slick-alt-2-dot-hover-border      				: 2px solid $action-alt;
$slick-alt-2-dot-active-bg-color   				: $action-alt;
$slick-alt-2-dot-active-border-color 			: 2px solid $action-alt;
$slick-alt-2-dot-active-hover-bg-color 		: $action-alt-hover;
$slick-alt-2-dot-active-hover-border-color 		: 2px solid $action-alt-hover;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : $slide-bg-color;
$slide-text-color          : white;
$slide-text-font           : 1.375rem/1.45 $title-font-family;