.view-ivvn-vetvaxhub {
	.view-content .views-row {
		.card.card-with-image {
			.card-text {
				.member-job {					
          .card-institution {
            &:after {
              content: "";
              display: block;
              width: 100%;
              height: 1px;
              background-color: $maximum_blue_purple;
              margin: $vert-space 0;
            }
          }
				}
			}
		}
	}
}

.view-ivvn-members {
	.view-content .views-row {
		.card.card-with-image {
			.card-text {					
				.card-mail .card-mail-wrapper {
					&:before {
						content: "";
						display: block;
						width: 100%;
						height: 1px;
						background-color: $maximum_blue_purple;
						margin: $vert-space 0;
					}
				}
			}
		}
	}
}

.view-ivvn-members, //.view-display-id-page {
.view-ivvn-vetvaxhub {
	.view-content .views-row {
		.card.card-with-image {
			display: flex;
			flex-direction: row;

			.card-photo {
				align-self: flex-start;
				width: 30%;
				max-width: 160px;
				min-width: 100px;
				
				@media (max-width: 719px) and (min-width: 540px) {
	    		min-width: 80px;
				}
			}
			.card-text {
				margin-top: 20px;
				margin-right: 0;
				margin-left: 0;
				align-self: flex-start;
				width: 100%;
				
				.member-name {
					margin-bottom:$vert-space/4;
					div { display: inline; }
				}
				
				.member-job {
					font-size: 0.889rem;
					margin-bottom: $vert-space/2;
          
          .card-job-title {
            font-size: 1rem;
            color: $secondary;
          }
          .card-institution {
            color: $dim_gray;
            font-size: 0.889rem;
          }
				}
				
				.card-mail {
					font-size: 0.889rem;
				}
				
				.label {
          color: $primary;
					margin-right: $horz-space/4;
          float: left;
          font-size: 0.889rem;
          
          & + p {
            margin-bottom: 5px;
            font-size: 0.889rem;
          }
				}
        
        .member-taxonomies {
          li,
          .card-vetvaxhub-country {
            font-size: 0.889rem;
            text-transform: uppercase;
            display: inline-block;
            border: 1px solid $glitter;
            padding: 0px 5px;
            margin: 0 3px 5px 0;
            font-weight: 600;
          }
        }
			}
		}
	}
	
	.views-summary { 
		a { 
			padding:8px 15px;
			color:$button-text;
			background-color:$button-bg;
			font: $button-font;
			font-size:22px;
			line-height:50px; 

			&:hover {
				color:$button-hover-text;
				background-color:$button-hover-bg;
			}
		}
	}
	
	.attachment {
		margin: $vert-space*1.5 0 $vert-space*2 0;
	}
	
	.views-exposed-form {
		// .views-exposed-widget.views-widget-filter-combine {
		.views-exposed-widgets {
			@include media (1340px) {
		    display: flex;
				flex-wrap: wrap;
			}
			
			& > .views-exposed-widget{
				width: 100%;

				@include media (590px) {
					width: calc(50%)
				}

				@include media ($narrow) {
					width: calc(100%)
				}

				@include media (950px) {
					width: calc(50%)
				}

				@include media (1340px) {
					width: calc(100%/3)
				}
			}
		}
				
		.views-exposed-widget-submit {
			@include media ($normal) {
				clear: none;
			}			
			@include media (1340px) {
				width: 100%;
		    display: flex;
		    justify-content: flex-end;
			}
		}
	}
	
	.leaflet-ivvn-pointer {
		background: $map-pointer no-repeat center top / contain;
		margin-left: -13px !important;
		margin-top: -40px !important;

	}
	.marker-cluster-small,
	.marker-cluster-medium,
	.marker-cluster-large {
		background: none;
		div {
			background: $map-pointer no-repeat center center / contain;
			height: 36px !important;
			margin-top: -15px !important;

		}	
	}
	.result-count {
		padding: 30px 0 0;
		font-size: 1rem; //1.125rem;
	}
	
	.leaflet-popup {
		
		a {
			color: $cg_blue;
			&:hover {
				color: $ube;				
			}
		}
		.leaflet-real-name {
			display: none;
		}		
		.label { 
			font-weight:bold;
			margin-right: $horz-space/4;
		}		
		.leaflet-popup-content-wrapper {
			border-radius: $card-border-radius;
		}
		.leaflet-popup-content-wrapper,
		.leaflet-popup-tip {
			color: $dim_gray;
			font-family:$base-font-family;
			box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
		}
		.leaflet-popup-content {
			margin: 0;
			
			.member-name {
				margin-bottom:$vert-space/4;
			}
			.member-job {
				font-size: 0.889rem;
				margin-bottom: $vert-space/2;

				div {
					display: inline;
					color: $dim_gray;
				}

				.card-job-title + .card-institution:before { 
					content: ",";
					display: inline;
					margin: 0 2px 0 -4px;
				}
			}
		}
	}
}

.view-ivvn-vetvaxhub .section-inner {
  display: flex;
  flex-flow: column wrap;
  position: relative;
  
  & > .view-filters {
    margin-bottom: -20px;
    order: 1;
    position: relative;

    .views-exposed-form {	

      .views-exposed-widget.views-widget-sort-by {
        position: absolute;
        right: 0;
        padding: 0;
        bottom: -60px;

        label {
          display: none;
        }

        @include media ($narrow) {
          label {
            display: inline-block;
            margin-right: 5px;
          }
          select#edit-sort-by {
            display: inline-block;
          }
        }
      }
      
      #edit-field-vetvaxhub-role-tid-selective-wrapper, #edit-field-vetvaxhub-dev-stage-tid-selective-wrapper {
        width: 100%;
        margin-bottom: $vert-space;
        
        @media (max-width: 719px) and (min-width: 540px) {
          width: 50%;
        }
                
        @include media ($narrow) {
          width: 30%;   
          margin-right: 1rem;
          margin-bottom: 0;
        }
        
        @media (min-width: 1060px) {
          width: 25%;       
        }
      }
      
      .views-exposed-widget-selects {
        float: left;
        width: 100%;
        
        @include media ($narrow) {
          width: 30%;
        }
        
        @media (min-width: 1060px) {
          width: calc(50% - 2rem);       
        }
        
        .views-exposed-widget {
          width: 100%;
        
          @media (max-width: 719px) and (min-width: 540px) {
            width: 50%;
          }
          
          @include media ($narrow) {
            width: 210px;
          }
          
          @media (min-width: 1060px) {
            width: 220px;
          }
          
          @media (min-width: 1260px) {
            width: 260px;
          }
          
          select {
            width: 100%;
          }
        }
      }

      .views-exposed-widget-submit {
        @include media ($normal) {
          clear: both;
          margin-top: 0; 
        }
      }
    }
  }
  & > .view-header {
    order: 2;
    margin-bottom: $vert-space*1.5;
  }
  & > .view-content {
    order: 3;
  }
}