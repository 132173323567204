.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	max-width: 600px;
	padding: 0 130px; 
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	min-height: 30px;
	
	@media screen and (max-width: $narrow) {
		max-width: 100%;
	}

	li {
		padding: 0;
		
		&.pager-item {
			display: inline-block;
			margin: 7px 3px;

			a {
				background: $caribbean_green;
				color: $dim_gray;
				font-weight: normal;
				padding: 2px 0 0;
				background: transparent;
				display: block;
				border-radius: 2px;
				width: 32px;
				height: 32px;
				border-radius: 32px;
				//border: none;
				//font-size: 0.889rem;

				&:hover {
					background:$ube;
					color:$action-hover;
					//border-color: $action-hover;
				}
			}
			
			@media screen and (max-width: $narrow) {
				display: none;
			}
		}
		
		&.pager-current {
			background-color: $white_smoke;
			font-weight: normal;
			padding:2px 0 0;
			display: inline-block;
			margin: 7px 3px;
			width: 32px;
			height: 32px;
			border-radius: 32px;
			color:$action-hover;
			//border: 1px solid $action-hover;
			
			@media screen and (max-width: $narrow) {
				display: none;
			}
		}
		
		
		&.pager-next {
			right: 65px;
			a {
				background: $arrow-white-right 50% 50% / 20px 20px no-repeat;

				&:hover {
					background: $arrow-white-right 50% 50% / 20px 20px no-repeat;
				}
			}
		}
		&.pager-last {
			right: 0;
			a {
				background: $double-arrow-white-right 50% 50% / 24px 20px no-repeat;

				&:hover {
					background: $double-arrow-white-right 50% 50% / 24px 20px no-repeat;
				}
			}
		}
		&.pager-previous {
			left: 65px;
			a {
				background: $arrow-white-left 50% 50% / 20px 20px no-repeat;

				&:hover {
					background: $arrow-white-left 50% 50% / 20px 20px no-repeat;
				}
			}
		}
		&.pager-first {
			left: 0;
			a {
				background: $double-arrow-white-left 50% 50% / 24px 20px no-repeat;

				&:hover {
					background: $double-arrow-white-left 50% 50% / 24px 20px no-repeat;
				}
			}
		}
		&.pager-next,
		&.pager-last,
		&.pager-previous,
		&.pager-first {
			position: absolute;
			a {
				display: block;
				background-color: $action-alt;
				border: none;
				border-radius: none;
				text-indent: -9999px;
				width: 24px;
				height: 20px;
				padding: 25px;
    		border-radius: 50px;
				
				&:hover {
					background-color: $action-alt-hover;
				}
			}
		}
		
		&.pager-last,
		&.pager-first {
			a {
				width: 24px;
				height: 20px;
			}
		}
	}
}



// TAGGED VIEW
.view-tagged {
	.item-list  ul.pager {		
		.pager-current {
			//color:$action-default;
			border: none;
			width: auto;
			
			@media screen and (max-width: $narrow) {
				display: inline-block;
			}
		}
	}
}


// PALETTE HIGHLIGHT //
.palette-highlight {
	.item-list ul.pager li {
		&.pager-item {
			a {
				background: $caribbean_green;
				color: white;
				
				&:hover {
					background: $ube;
					color:white;
				}
			}
		}
		
		&.pager-current {
			color:white;
			background-color: $dark_imperial_blue;
		}

//		&.pager-next {
//			a {
//				background-image: $arrow-white-right;
//
//				&:hover {
//					background-image: $arrow-white-right;
//				}
//			}
//		}
//		&.pager-last {
//			right: 0;
//			a {
//				background-image: $double-arrow-white-right;
//
//				&:hover {
//					background-image: $double-arrow-white-right;
//				}
//			}
//		}
//		&.pager-previous {
//			left: 65px;
//			a {
//				background-image: $arrow-white-left;
//
//				&:hover {
//					background-image: $arrow-white-left;
//				}
//			}
//		}
//		&.pager-first {
//			left: 0;
//			a {
//				background-image: $double-arrow-white-left;
//
//				&:hover {
//					background-image: $double-arrow-white-left;
//				}
//			}
//		}
	}
	
	
	.view-tagged {
		.item-list  ul.pager {		
			.pager-current {
				color:white;
			}
		}
	}
}

.palette-default,
.palette-alt-1,
.palette-alt-2,
.palette-alt-3 {
	.item-list ul.pager li {
		&.pager-item {
			a {
				&:hover {
					background: $ube;
					color:white;
				}
			}
		}
	}
}
