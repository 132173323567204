.i18n-en .site-title a { 
	background:$logo-mobile no-repeat scroll 0 0 transparent;
	
	@include media(880px) {
		background:$logo-en no-repeat scroll 0 0 transparent;
	}
}
//.i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
//.i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;	
		&:after {
		content: " ";
		display: block;
		height: 4px;
		clear: both;
		background: linear-gradient(90deg, #30B88D 0%, #167C98 50%, #7B7DBC 100%);
	}
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-outer-wrapper {
		position:relative;
		
		
		@media (max-width:879px) {
			display: flex;
    	flex-wrap: wrap;
			
			.region-header-wrapper {
				order: 1;
				width: 100%;
			}
			.header-inner-wrapper {
				order: 2;
			}
			.main-nav-wrapper {
				width: 100%;
    		position: absolute;
				bottom: 0;
			}
		}
		
		@include outer-container($site-max-width);
	}
	.site-branding {
		margin-top:0;
		margin-left:1.125rem;
		position: relative;
		float: left;
		
		@include media(880px) {
			margin-top:1.125rem;
		}
		.site-title {
			position:relative;
			//left:0;
			//margin-top:1.125rem;

//			@media (min-width:880px) and (max-width:1150px) {
//			max-;
//			}

			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:180px;
				height:90px;
				background-position-y: 100%;
				
				@include media(880px) {
					width:380px;
				}
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
	
	.wrapper-region-header {	
		display: flex;
		flex-direction: row-reverse;

		ul {
			padding-left: 0;
		}
		
		.block-search {
			//float:right;
			order: 1;
			//height:10rem;
			@include media(880px) {
				height:auto;
			}
			h2 {
				font-family:$base-font-family;
				font-size:1rem;
				color:$primary;
				float:left;
				line-height:1.5;
				margin-top:1rem;
				margin-right:0.75rem;
				cursor:pointer;
				display:none;
				@include media(880px) {
					margin-right:2.5rem;
				}
			}
			.form-wrapper {
				display:none;
				@include media(930px) {
					display:block;
				}
			}
			.form-item-search-block-form {
				margin-top:0.75rem;
				margin-right:0.75rem;
				margin-bottom:0;
				@include media(880px) {
					margin-bottom:1rem;
				}
				@include media($wide) {
					margin-bottom:1rem;
				}
				float:left;
				input.form-text {
					width:10rem;
					height:1.875rem;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font;
					padding:0.375rem 0.75rem;
				}
			}
			.form-actions {
				float:left;
	//			margin-top:0.75rem;
	//			@include media(880px) {
	//				margin-bottom:5rem;
	//			}
				input.form-submit {
					background: transparent $search-grey no-repeat scroll 50% 50% / 14px;
					width:1rem;
					height:1rem;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.45rem;
          border: none;

					&:hover {
						background: transparent $search-hover no-repeat scroll 50% 50% / 14px;
            border: none;
					}
				}
			}
		}
		
		.block-menu {
			order: 2;
//			float:right;
//			margin-top:1rem;
//			margin-right:0.5rem;
//			@include media(880px) {
//				margin-right:1.5rem;
//				margin-bottom:5rem;
//			}
			ul {
				margin-bottom: 0;
				
				li {
					display:inline-block;
					margin:0 0.75rem 0 0;
				}
			}

			a {
				font:$header-menu-font;
				color:$header-menu-link-color;

				&:hover {
					color:$header-menu-link-hover;
				}
			}
			&.header-menu {
				li {
					@include media(930px) {
						&.last {
							display: none;
						}
					}
				}
			}
		}
		.block-locale {
			clear:both;
			display:none;
		}
	}
}