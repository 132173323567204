// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
		
		@media (min-width:880px) and (max-width:1150px) {
			min-height:46px;
		}

		@include media(1150px) {
			min-height:46px;
		}
	}
}

// MENU STYLES
#site-header {
	@include media(880px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	} 
	.block-menu-block {
		clear:right;
		position: relative;
		
		.main-menu-btn {
			//background-color:$nav-button-bg-color;
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			margin: -78px 0px 0px 0px;
						
			.main-menu-btn-icon {
 				left:20px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;
				
				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width; 
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
				
				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			} 
		}
		
		ul.sm {
			background: none; 
			clear:both;
		}
		
		@include media(880px) { 
			clear:right;
			float:right;
		}		
		
		.menu-block-wrapper ul.menu.sm  {
			
			border:none;
			box-shadow:none;
					
			& > li {
				border-left:none;
				flex-grow: 1;
				text-align: center;
				

				&:last-child { 
					position:relative;
          
          // THE HUB
          // @include media(880px) { 
          //   a {
          //     background-color: white;
          //     border: 1px solid $caribbean_green;
          //     color: $caribbean_green;
          //     border-radius: 50px !important;
          //     padding: 0.489rem 0.889rem;
          //     margin: 0.45rem 0.2rem 0.25rem;
          //     border-radius: 50px;
					// 
          //     @include media(1150px) { 
          //       padding: 0.489rem 0.889rem;
          //       margin: 0.45rem 0.2rem 0.25rem;
          //     }
					// 
          //     &:hover,
          //     &.active.current:hover {
          //       background-color: $caribbean_green;
          //       border: 1px solid $caribbean_green;
          //       color: white;
          //       padding: 0.489rem 0.889rem;
          //       margin: 0.45rem 0.2rem 0.25rem;
					// 
          //       @include media(1150px) { 
          //         padding: 0.489rem 0.889rem;
          //         margin: 0.45rem 0.2rem 0.25rem;
          //       }
          //     }
					// 
          //     &.active.current {
          //       background-color: white;
          //       border: 1px solid $ube;
          //       color: $ube;
					// 
          //       &:after {
          //         bottom: -22px;
          //       }
          //     }
          //   }
          // }
				}

				@include media(880px) { 					
					& > ul.menu {	
						margin-top: 4px !important;
						border-top: $nav-sub-top-border !important;
					}
					
					& > a > .sub-arrow {
						display:none;
					}
				}

				& > a {
					background-color: $nav-base-bg-color;
					text-align: center;
					font:$nav-base-font;
					color:$nav-base-text-color;
					padding:$nav-base-padding;
					//text-transform: uppercase;
          
          @include media(880px) {
            letter-spacing: 1.5px;
          }

					@media (min-width:880px) and (max-width:1150px) {
						font: $nav-base-font-smaller;
						padding: $nav-base-padding-smaller;
					}

					&:hover {
						border-bottom: $nav-hover-border;
						padding:$nav-base-hover-padding;
						background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}			
					&.current,
					&.active.current {
						background-color:$nav-active-bg-color;
						color:$nav-active-text-color;
						border-radius:$nav-active-border-radius;
						position: relative;
						
						@include media(880px) {
							&:after {
								content: "";
								position: absolute;
								left: calc(50% - 15px);
								width: 30px;
								height: 30px;
								bottom: -17px;
								background:$menu-active no-repeat 50% 50% / 30px transparent;
								z-index: 999;
							}
						}
						
						&:hover {							
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}						
					}
					
					&.active.current.highlighted,
					&.current.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-radius:$nav-active-border-radius;
						
						&:hover {							
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}
					}

					&.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-bottom: $nav-highlight-border;
// >>						border-top: none;
						padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}
					}
				}
			}
			
			li {
				a {
					cursor:pointer; 
					background-color: transparent;
										
					.sub-arrow {
						background:$arrow-grey-right no-repeat scroll center center transparent;
						background-size:contain;
						width: 16px;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}
				
				ul.menu {	
					background:$nav-sub-bg-color;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					box-shadow:$nav-sub-box-shadow;
					
					li {
						border-top:none; 
						
						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;
							
							&.current {
								background:$nav-sub-bg-color;
							}
							
							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}  
							&.active {
								background-color:$nav-sub-active-bg-color;
								color:$nav-sub-active-text-color;
								
//								.sub-arrow {
//									background:$arrow-green-right no-repeat scroll center center transparent;
//									background-size:contain;
//								}
								
								&:hover {
									background-color:$nav-sub-hover-bg-color;
									color:$nav-sub-hover-text-color;
								}  
							} 
							
							&.active-trail {
								
								.sub-arrow {
									background:$arrow-grey-right no-repeat scroll center center transparent;
									background-size:contain;
								}
								
								&:before {
									content: " ";
									width: 4px;
									height: 4px;
									background-color: $nav-active-text-color;
									position: absolute;
									display: block;
									top: calc(50% - 2px);
									left:7px;
									border-radius: 50%;
								}
								
								&:hover {
									&:before {
										background-color: white;
									}
								}
							}
							.sub-arrow {
								background:$arrow-grey-right no-repeat scroll center center transparent;
								background-size:contain;
								text-indent:-999em;
								overflow:hidden;
								right:10px;				
							}	
							
							&.active {
								.sub-arrow {
									background:$arrow-secondary-right no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
							
							&:hover, 
							&.active:hover  {
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:contain;
								}								
							}							
						}						
						&.expanded {
							
							& > a.highlighted { 
								background-color:$nav-highlight-bg-color;
								color:$nav-highlight-text-color;

								.sub-arrow {
									background:$arrow-green-right no-repeat scroll center center transparent;
									background-size:contain;
								}
								
								&.current.active {
									background-color:$nav-highlight-bg-color;
									color:$nav-highlight-text-color;
									
									&:before {
										background-color: $nav-highlight-text-color;
									}
									
									.sub-arrow {
										background:$arrow-green-right no-repeat scroll center center transparent;
										background-size:contain;
									}	
								}

								&:hover,
								&.current.active:hover {
									background-color:$nav-sub-hover-bg-color;
									color:$nav-hover-text-color;
									
									.sub-arrow {
										background:$arrow-white-right no-repeat scroll center center transparent;
										background-size:contain;
									}	
								}
								
								&.active-trail:hover {
									&:before {
										background-color: white;
									}
								}
							}
						}
					}
				}
			}
			
		}	
		
////////////////////////////////////      MOBILE      ////////////////////////////////////
		@media (max-width:879px) {
		
			#main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: -4px; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }


			.menu-block-wrapper ul.menu.sm {
				
				border: none; //$nav-mob-border;
				border-top: $nav-mob-top-border; 
				box-shadow: $nav-mob-shadow; //0 2px 4px 0 rgba(0,0,0,0.12);
				
				position: absolute;
				right: 0;
				width: calc(100% - 5%); 
				max-width: 400px;
				z-index: 1005;
				
				//@media (min-width:$narrow) and (max-width:879px) {
					top: 3px;
				//}
				
				& > li {
					
					&.first.leaf {
						//border: none;
						//border-top:$nav-mob-expanded-border;
					}
					&.first.first {
						border: none;
					}
					
					a .sub-arrow {
						width: 34px;
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						background-color:$nav-mob-1st-bg-color;
						text-align: left;
						font:$nav-base-font;
						color:$nav-sub-text-color;
						padding:$nav-sub-base-padding;
						//text-transform: uppercase;
						background-image: none !important;

						&:hover {
							background-color:$nav-sub-hover-bg-color;
							color:$nav-sub-hover-text-color;
							padding:$nav-sub-base-padding;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}	
						}

						&.current { 
							//background:$nav-mob-1st-highlight-bg-color;
							//color:$nav-mob-1st-highlight-text-color;
							background-color:$nav-mob-1st-bg-color;
							color:$nav-sub-text-color;
							
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;
							border-top:$nav-mob-expanded-border;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {						
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						}

						&.active,
						&.active.current { 
							//background-color: $nav-mob-active-bg-color;
							background-color:$nav-mob-1st-bg-color;
							color: $nav-mob-active-text-color;
							.sub-arrow {
								background:$arrow-secondary-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								} 
							}
						}

						&.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;
							
//							// LEVEL BORDERS: TOP  //
							border-top: 1px solid $nav-mob-1st-border-color;
							
							.sub-arrow {
								background:$arrow-white-up no-repeat scroll center center transparent;
								background-size:50%;						
							}
							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-white-up no-repeat scroll center center transparent;
									background-size:50%;
								}	
							}
						}

						//&.active-trail,
						&.current.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-highlight-border;
							padding:$nav-sub-base-hover-padding;
							
							&:before {
								background-color: $nav-sub-hover-text-color;
							}

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-sub-hover-text-color;
							}
						}
						
						&.active-trail {
								
							.sub-arrow {
								background:$arrow-grey-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							
							&.highlighted {
								.sub-arrow {
									background:$arrow-white-up no-repeat scroll center center transparent;
									background-size:50%;
								}
								
								&:hover {								
									.sub-arrow {
										background:$arrow-white-up no-repeat scroll center center transparent;
										background-size:50%;
									}
								}
							}

							&:before {
								content: " ";
								width: 4px;
								height: 4px;
								background-color: $nav-active-text-color;
								position: absolute;
								display: block;
								top: calc(50% - 2px);
								left:7px;
								border-radius: 50%;
							}

							&:hover {								
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
								
								&:before {
									background-color: white;
								}
							}
						}

						&.active.highlighted.current {
							color: $nav-mob-1st-highlight-bg-color;
							
							&:before {
								background-color: $nav-mob-1st-highlight-bg-color;
							}
							
							.sub-arrow {
								background:$arrow-green-up no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:hover {
								&:before {
									background-color: $nav-sub-hover-text-color;
								}
								.sub-arrow {
									background:$arrow-white-up no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						} 
					}
				}
				
				li {
					border: none;
					
					a {
						border-top: $nav-mob-border;
						border-left: none;
						padding-left: 0.875rem;
						
						color: $nav-mob-text-color;
						.sub-arrow {
							background:$arrow-grey-down no-repeat scroll center center transparent;
							background-size:50%;
							height: 46px;
							margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}
					
					
					///////////////////////////////      NEXT LEVELS      ///////////////////////////////
					
					/* ul BACKGROUNDS */
					&.expanded {
						
						// 2nd level //
						
						ul.menu {
							border-bottom: $nav-mob-border;
						}
						ul.menu,
						ul.menu a.current {
							background-color: $nav-mob-2nd-bg-color;
							border-top: 2px solid $nav-mob-1st-border-color;	
							
							li.first a {
								border-top:none;
							}

							li:not(.first) a {
								border-top: 1px solid $nav-mob-2nd-line-color;
							}
							
//							// LEVEL BORDERS: BOTTOM (TOP: Look 2nd level a.highlighted)  //
//							li.last {					
//								border-bottom: 1px solid $nav-mob-1st-border-color;
//								margin-bottom: -1px;
//								z-index: 1;
//								position: relative;
//							}
							
							a {
								border-left: none;
								padding-left: 1.375rem;								
							
								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $nav-mob-active-text-color;						
								}
							}
							
							&.active-trail {
								
								.sub-arrow {
									background:$arrow-grey-down no-repeat scroll center center transparent;
									background-size:50%;
								}
								
								&.active {
									.sub-arrow {
										background:$arrow-secondary-down no-repeat scroll center center transparent;
										background-size:50%;
									}
									
									&:hover {								
										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}
								
								&.active.highlighted {
									.sub-arrow {
										background:$arrow-green-up no-repeat scroll center center transparent;
										background-size:50%;
									}
									
									&:hover {								
										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}
								
								&.highlighted {

									&:before {
										background-color: $nav-hover-text-color;
									}
									
									.sub-arrow {
										background:$arrow-white-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover {								
										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}

										&:before {
											background-color: $nav-hover-text-color;
										}
									}
								}

								&:hover {
									background-color:$nav-sub-hover-bg-color;
									color:$nav-hover-text-color;
									
									.sub-arrow {
										background:$arrow-white-down no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:before {
										background-color: $nav-hover-text-color;
									}
								}
							}

							li.expanded {
								
								// 3rd level //
								ul.menu {	
									background-color: $nav-mob-3rd-bg-color;
									border-top: 2px solid $nav-mob-2nd-border-color;	

									li.first a {
										border-top:none;
									}

									li:not(.first) a {
										border-top: 1px solid $nav-mob-3rd-line-color;
									}
							
//									// LEVEL BORDERS: BOTTOM (TOP: Look 3rd level a.highlighted)  //
//									li.last {					
//										border-bottom: 1px solid $nav-mob-2nd-border-color;
//										z-index: 2;
//									}
							
									a {
										border-left: none;
										padding-left: 1.875rem;								

										&.active {
											background-color: $nav-mob-active-bg-color;
											color: $nav-mob-active-text-color;						
										}
									}
								}
							}
						}
					}
					
					/* ARROWS + HIGHLIGHT */ 
					ul.menu {	
						box-shadow: none;
						
						li {
							//border-top:$nav-mob-expanded-border;
							border-top: none;
							
							a {
								color: $nav-mob-text-color;
								.sub-arrow {
									background:$arrow-grey-down no-repeat scroll center center transparent;
									background-size:50%;
									right:0;
									height: 39px;
									margin-top: -19px;
									//border-left: $nav-mob-border;
								}	
															
								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $nav-mob-active-text-color;	
									
									.sub-arrow {
										background:$arrow-green-down no-repeat scroll center center transparent;
										background-size:50%;
									}
								}

								&:hover, 
								&.active:hover  {
									.sub-arrow {
										background:$arrow-white-down no-repeat scroll center center transparent;
										background-size:50%;
									}								
								}
							}
							
							&.expanded {								
								ul.menu a.current {
									background-color: $nav-mob-2nd-highlight-bg-color;

									&.active {
										background-color: $nav-mob-active-bg-color;
										color: $nav-mob-active-text-color;
										
										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}

									&:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}
								
								////////////**     2nd LEVEL     **////////////
								& > a.highlighted { 
									background-color:$nav-mob-2nd-highlight-bg-color;
									color:$nav-mob-2nd-highlight-text-color;
									
//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-2nd-border-color;

									.sub-arrow {
										background:$arrow-white-up no-repeat scroll center center transparent;
										background-size:50%;
									}
									
									&.current.active {
										//background-color:$nav-mob-2nd-highlight-bg-color; //nav-sub-active-bg-color;
										//color:$nav-mob-2nd-highlight-text-color; //nav-active-text-color;
										
//										background-color: $nav-mob-active-bg-color;
//										color: $nav-mob-active-text-color;

										.sub-arrow {
											background:$arrow-green-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}

									&:hover, 
									&.current.active:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded > a.highlighted { 
									background-color: $nav-mob-3rd-highlight-bg-color;
									color: $nav-mob-3rd-highlight-text-color;
									
//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-3rd-border-color;

									.sub-arrow {
										background:$arrow-green-up no-repeat scroll center center transparent;
										background-size:50%;
									}
									
									&.current.active {
//										background-color: red; //$nav-mob-3rd-highlight-bg-color;
//										color:$nav-mob-3rd-highlight-text-color;
										
										background-color: $nav-mob-active-bg-color;
										color: $nav-mob-active-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}

									&:hover, 
									&.current.active:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}							
								}
							}
						}
					}
				}
			}
		}
	}
}