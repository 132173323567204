.page-user {
	
	#content > h1 {
		display: none;
	}
	
	.card-suffix {
		display: inline;
	}
	
  .card-profile {
    
    .label {
      font-weight: 400;
      font-size: 1.1rem;
    }
    .card-profile-image {
      margin-bottom: $vert-space;
      text-align: center;

      .card-ivvn-user-photo img {
        max-width: 360px;
        width: 100%;
      }		

      .card-ivvn-person-er {
        margin: $vert-space 0;
      }

      @include media($narrow) {
        float: left;
        max-width: 240px;
        margin-right: $horz-space*2;
      }
    }
    
    .card-profile-details {
      overflow: hidden;

      .group_member_name > div {
        display: inline-block;
      }
      
      .group-member-job {
        margin-bottom: $vert-space;
        .card-job-title {
          font-size: 1.333rem;
          color: $secondary;
        }
        .card-institution,
				.card-ivvn-career-stage {
          color: $dim_gray;
          font-size: 0.889rem;
        }
      }
      
      .card-profile-role-stage {
        .label {
          // display: none;
					margin: $vert-space/4 0;
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
				}
          
        li {
					display: inline-block;
					
					span {
	          font-size: 0.889rem;
	          text-transform: uppercase;
	          display: inline-block;
	          border: 1px solid $glitter;
	          padding: 0px 5px;
	          margin: 0 3px 5px 0;
	          font-weight: 600;
	        }
				}
				.hierarchical-item,
				div > span {
          font-size: 0.889rem;
          text-transform: uppercase;
          display: inline-block;
          border: 1px solid $glitter;
          padding: 0px 5px;
          margin: 0 3px 5px 0;
          font-weight: 600;
        }
				
				.hierarchical-item {
					span {
						border: none;
	          padding: 0px;
	          margin: 0 5px 0 0;
					}
				}
      }

      .card-profile-contact {
        margin: $vert-space/4 0;
        .card-contact-number,
        .card-mail,
        .card-linkedin {
          margin-bottom: $vert-space;
        }
        @include media(1250px) {
          .card-contact-number,
          .card-mail,
          .card-linkedin {
            display: inline-block;
          }
          .card-contact-number,
          .card-mail {
            margin-right: $horz-space*4;
          }
        }
      }
    }
  }
  
  .card-profile-additional {
    overflow: hidden;
    clear: both;
    .label {
      font-weight: 300;
      font-size: 1.556rem;
    }
  }
	.card-profile-member-research {
		.label {
			// display: none;
			margin: $vert-space/4 0;
		}
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
			
		li {
			display: inline-block;
			
			span {
				font-size: 0.889rem;
				text-transform: uppercase;
				display: inline-block;
				border: 1px solid $glitter;
				padding: 0px 5px;
				margin: 0 3px 5px 0;
				font-weight: 600;
			}
		}
		.hierarchical-item,
		div > span {
			font-size: 0.889rem;
			text-transform: uppercase;
			display: inline-block;
			border: 1px solid $glitter;
			padding: 0px 5px;
			margin: 0 3px 5px 0;
			font-weight: 600;
		}
		
		.hierarchical-item {
			span {
				border: none;
				padding: 0px;
				margin: 0 5px 0 0;
			}
		}
	}
}